/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import "~normalize.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

* {
  --gray-25: #FCFCFD;
  --gray-50: #F9FAFB;
  --gray-100: #F2F4F7;
  --gray-200: #EAECF0;
  --gray-300: #D0D5DD;
  --gray-400: #98A2B3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #1D2939;
  --gray-900: #101828;
  --primary-50: #E8F9E9;
  --primary-50: #CFFFD2;
  --primary-100: #C1F4C4;
  --primary-200: #A0DEA4;
  --primary-300: #8BDA8F;
  --primary-400: #7BD380;
  --primary-500: #61BD66;
  --primary-600: #4CB652;
  --primary-700: #00A859;
  --primary-800: #008446;
  --primary-900: #006837;
  --success-500: #12B76A;
  --error-100: #FEE4E2;
  --error-400: #F97066;
  --error-500: #F04438;
  --blue-300: #84CAFF;
  --blue-400: #53B1FD;
  --blue-500: #2E90FA;
  --side-nav-width: 215px;
}
* {
  --success-25: #f6fef9;
  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-200: #a6f4c5;
  --success-300: #6ce9a6;
  --success-400: #32d583;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;
  --side-nav-width: 215px;
}
html {
  background-color: var(--gray-300);
  font-family: 'Poppins';
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-end: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;

  -webkit-padding-before: 0;
  -webkit-padding-end: 0;
  -webkit-padding-after: 0;
  -webkit-padding-start: 0;
}

button {
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  border: none;
  &.disabled {
    opacity: 0.3;
  }
  &.primary {
    background-color: var(--primary-600);
    color: #ffffff;
  }
  &.secondary {
    background-color: #ffffff;
    border: 1px solid var(--gray-300);
    color: var(--gray-600);
  }
  &.light {
    background-color: var(--gray-200);
    color: var(--gray-600);
  }
  &.transparent {
    background-color: transparent;
    color: var(--gray-600);
    padding: 12px 8px;
  }
  &.outline {
    background-color: var(--gray-50);
    color: var(--gray-500);
    border: 1px solid var(--gray-300);
    display: flex;
    align-items: center;
    i {
      margin-left: 5px;
    }
  }
}

.p-datatable {
  border: 1px solid var(--gray-300);
  .p-datatable-header {
    padding: 0px;
    border-bottom: 1px solid var(--gray-300);
  }
  .p-datatable-thead > tr > th {
    border-bottom: 1px solid var(--gray-300);
  }
  .p-paginator-bottom {
    background: var(--gray-100);
    justify-content: end;
    border-top: 1px solid var(--gray-300);
  }
}

app-scenarios-table {
  .p-datatable {
    border: none;
    border-left: 1px solid var(--gray-300);
  }
}

.p-datatable .p-datatable-tbody > tr {
  background: var(--gray-50);
}
.formGroup {
  position: relative;
  i {
    position: absolute;
    top: 11px;
    left: 14px;
    color: var(--gray-500);
  }
  input {
    text-indent: 24px;
  }
}
input {
  background-color: var(--gray-300);
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;

}
.errorInput{
  border: 1px solid red !important;
  border-radius: 8px;
}
h3 {
  margin: 10px 0px;
  padding: 0px;
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 20px;
  color: var(--gray-500);
}
.p-inputtext {
  // background-color: var(--gray-300);
  // border: 1px solid var(--gray-300);
  padding: 10px 16px;
  border-radius: 8px;
  width: 100%;
}
.p-fileupload-basic	{

  .p-fileupload-choose{
    background-color: var(--gray-300);
    color: var(--gray-600);
    border-color: var(--gray-300);

    plusicon{
      margin-bottom: 1px;
    }

    &:hover{
      background-color: var(--gray-400);
      border-color: var(--gray-400);
      color: var(--gray-600);
    }
  }
}
button.p-button{
  background-color: var(--gray-500);
  color: var(--gray-200);
  border-color: var(--gray-500);

  &:enabled:hover{
    background-color: var(--gray-400);
    border-color: var(--gray-400);
    color: var(--gray-100);
  }
}
.p-dropdown-label{
  height: fit-content;
  margin: auto;
}
.p-paginator-element{
  border: 2px solid #d6dbe2 !important;
  border-radius: 8px !important;
  background-color: var(--gray-100) !important;
  min-width: 2.7rem !important;

  .p-icon-wrapper{
    svg{
      width: 1.3rem !important;
      height: 1.3rem !important;
    }
  }

  &.p-highlight{
    background-color: #ffffff !important;
    color: #7a8295 !important;
  }
}
.p-paginator-rpp-options{
  background-color: var(--gray-50);
  span{
    color: var(--gray-500);
  }
}

.pac-container{
  position: fixed;
  display: block;
  margin-top: -210px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

// Dialog -- Modales
.p-dialog{
  border-radius: 30px;

  .p-dialog-header{
    background-color: var(--gray-200);
    border-radius: 30px 30px 0px 0px;
    padding: 1rem 1.5rem;
    color: var(--gray-600);
    font-weight: 600;
    letter-spacing: .5px;
    font-size: 24px;
    line-height: 36px;
  }
  .p-dialog-content{
    background-color: var(--gray-100);
    padding: .5rem 1rem;
  }
  .p-dialog-footer{
    background-color: var(--gray-100);
    padding: .5rem 1rem 1rem 1rem;
    border-radius: 0px 0px 30px 30px;
  }
}

@keyframes rise{
	0% {transform: translatey(0px) scale(1);}
	15% {transform: translatey(-50px) scale(1.1);}
	45% {transform: translatey(-300px) scale(1.3);}
	75% {transform: translatey(-420px) scale(1.4);}
	100%{transform: translatey(-690px) scale(1.4);}
}

@keyframes newcard{
  0%   {transform: scale(1);}
  50%  {transform: scale(1.5);}
  100% {transform: scale(1);}
}

.summaryCarbono{
  top: 10px;
  left: 0px;
  position: absolute;
  color: var(--blue-300);
  animation: rise 7s linear;
  //-webkit-text-stroke: 1px var(--primary-300);
  filter: drop-shadow(rgba(0, 0, 0, 0.24) 0px 3px 8px);
}

html {
  height  : 100%;
  overflow: hidden;
  position: relative;
}
body {
  height  : 100%;
  overflow: auto;
  position: relative;
}

 /////SPINNER-LOADING////
 .lds-ring {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-ring div {
  &.google {
    border: 4px solid var(--font-color);
    border-color: var(--font-color) transparent transparent transparent;
  }
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cursor-pointer {
  cursor: pointer;
}


.loading-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: fixed;
  top: 0px;
  .img-cont {
    height: 12vh;
    width: 290px;
    background: linear-gradient(180deg, #039855 0%, #1570EF 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 5px 4px 20px 0px rgba(133, 133, 133, 0.63);
    .logo-ctrl {
      width: 80px;
      overflow: hidden;
      animation: spin 3.5s linear infinite;
    }
    img {
      width: 250px;
    }
  }
}


@keyframes spin {
  2% {
    width: 80px;
  }
  20% {
    width: 126px;
  }
  22% {
    width: 126px;
  }
  40% {
    width: 194px;
  }
  42% {
    width: 194px;
  }
  60% {
    width: 250px;
  }
  70% {
    width: 250px;
  }
  90% {
    width: 80px;
  }
  100% {
    width: 80px;
  }
}
